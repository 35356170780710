import Link from 'next/link';
import Image from 'next/future/image';
import cn from 'classnames';
import { PopularCategory as PopularCategoryT } from '@lambda/apis/src/category/types';
import Slider from '../slider/Slider';
import { Breakpoints } from '@/settings/breakpoints';
import smartphones from '@/public/images/categories/Smartphones.png';
import laptops from '@/public/images/categories/Laptops.png';
import smartwatches from '@/public/images/menu/Smartwatch.png';
import iPads from '@/public/images/menu/iPads.png';
import audio from '@/public/images/menu/Audio.png';
import kitchen from '@/public/images/menu/Kitchen.png';
import more from '@/public/images/categories/products.png';
import gaming from '@/public/images/menu/Gaming.png';
import accessories from '@/public/images/menu/access.png';
import outdoor from '@/public/images/menu/outdoor.png';
import cameras from '@/public/images/categories/Cameras.png';
import beauty from '@/public/images/categories/beauty.png';
import vacuum from '@/public/images/categories/Vacuum.png';
import tv from '@/public/images/categories/TVs.png';
import Section from '../commons/Section';

const breakpoints = {
  [Breakpoints.NONE]: {
    slidesPerView: 2.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.XXS]: {
    slidesPerView: 3.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.XS]: {
    slidesPerView: 4.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.SM]: {
    slidesPerView: 4.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.MD]: {
    slidesPerView: 5.5,
    slidesPerGroup: 1,
    spaceBetween: 15,
  },
  [Breakpoints.LG]: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 20,
  },
  [Breakpoints.XL]: {
    slidesPerView: 8,
    slidesPerGroup: 8,
    spaceBetween: 20,
  },
};

const getCategoryImage = (handle: string | undefined) => {
  if (!handle) return '';

  const images: { [key: string]: StaticImageData } = {
    smartphones,
    laptops,
    smartwatches,
    tablets: iPads,
    audio,
    gaming,
    accessories,
    cameras,
    tv,
    outdoor,
    'health-beauty': beauty,
    'vacuum-cleaners': vacuum,
    'home-appliances': kitchen,
    'more-devices': more,
  };

  return images[handle];
};

const PopularCategories = ({
  isAccount,
  categories,
}: {
  isAccount?: boolean;
  categories?: PopularCategoryT[];
}) => {
  // Get in stock menu items
  const inStockCategories = categories || [];

  return (
    <Section>
      <div className={cn({ 'reebelo-container': !isAccount })}>
        <h2 className="flex items-center text-lg font-semibold md:text-xl">
          Popular Categories
        </h2>
        <div className="relative mt-6">
          <Slider
            speed={300}
            breakpoints={breakpoints}
            arrowClassName="top-[55px]"
          >
            {inStockCategories.map((category) => {
              const generateBadge = () => {
                if (category.handle === 'smartphones') return 'Popular';
                if (category.handle === 'home-appliances') return 'New';

                return '';
              };
              const badge = generateBadge();

              return (
                <Link
                  href={`/collections/${category.handle}`}
                  key={category.handle}
                >
                  <a className="flex flex-col items-center">
                    <div className="relative flex h-20 w-20 items-center justify-center rounded-full bg-gray-200 sm:h-24 sm:w-24 md:h-28 md:w-28">
                      {badge && (
                        <div className="absolute left-0 top-0 rounded-full bg-gray-700 px-2 py-1 text-[10px] text-white">
                          {badge}
                        </div>
                      )}
                      <div className="h-16 w-16 sm:h-20 sm:w-20 md:h-24 md:w-24">
                        <Image
                          width={100}
                          height={100}
                          className="h-full w-full object-contain"
                          src={getCategoryImage(category.handle)}
                          alt={`${category.handle}-link`}
                          quality={100}
                        />
                      </div>
                    </div>
                    <h3 className="mt-3 w-full truncate text-center text-sm">
                      {category.title}
                    </h3>
                  </a>
                </Link>
              );
            })}
          </Slider>
        </div>
      </div>
    </Section>
  );
};

export default PopularCategories;
